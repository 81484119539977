import { decode, encode } from 'base-64'
import Cookies from 'js-cookie'

function getAuthCookie(): string | null {
  const cookie = Cookies.get('person_auth_token')
  if (!cookie) {
    return null
  }
  try {
    return JSON.parse(decode(cookie))
  } catch {
    return null
  }
}

function setAuthCookie(value: string): void {
  Cookies.set('person_auth_token', encode(JSON.stringify(value)), {
    domain: import.meta.env.VITE_DOMAIN || '.inctrl.app',
  })
}

function removeAuthCookie(): void {
  Cookies.remove('person_auth_token', {
    domain: import.meta.env.VITE_DOMAIN || '.inctrl.app',
  })
}

function getPortalRedirectCookie(): string | null {
  return Cookies.get('portal_redirect', {
    domain: import.meta.env.VITE_DOMAIN || '.inctrl.app',
  })
}

function removePortalRedirectCookie(): void {
  Cookies.remove('portal_redirect', {
    domain: import.meta.env.VITE_DOMAIN || '.inctrl.app',
  })
}

type getAuthCookie = () => string | null
type setAuthCookie = (value: string) => void
type removeAuthCookie = () => void

export {
  getAuthCookie,
  setAuthCookie,
  removeAuthCookie,
  getPortalRedirectCookie,
  removePortalRedirectCookie,
}
